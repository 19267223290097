import React from "react";
import '../assets/css/templates/landing-template.css';
import { Helmet } from "react-helmet";
import { ContextWithLandingItemProps } from "../types/landingItem";
import { Link } from "gatsby";
import ReactGA from 'react-ga';

const LandingTemplate: React.FC<ContextWithLandingItemProps> = ( { pageContext: { id, name, iconName, screenshotName, description, privacyPolicyId, iosLink, androidLink} }) => {
  
    React.useEffect(() => {
  		ReactGA.initialize('UA-88504612-1');
  		ReactGA.pageview(`/landing/${id}`);
  	}, []);
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <div className="landing-overflow"></div>
      <div className="landing">
       <div className="container">
          <div className="responsive intro-container">
            <div style={{textAlign: "center", width:"100%"}} className="app-icon center-mobile-version">
              <img className="landing-img" style={{width:"90px"}} alt="App icon" src={require(`../assets/images/${iconName}`).default} />
              <div style={{fontWeight:"200", width:"100%", fontSize:"35px", marginTop: "7px", marginLeft: "2px"}}>{name}</div>
          </div>
          <div className="intro">
            <h2>{description}</h2>
          </div>
          {iosLink && (
            <a className="primary-btn app-store-btn" href={iosLink}>Get it on the App Store</a>
          )}
           {androidLink && (
            <a className="primary-btn" href={androidLink}>Get it on the Play Store</a>
          )}
        </div>
        <div style={{height: "auto"}} className="responsive iphone">
          <div className="iphone-screenshot">
            <img className="landing-img" src={require(`../assets/images/${screenshotName}`).default} alt="App screenshot" />
          </div>
          <img className="landing-img iphone-mask" src={require(`../assets/images/iphone.png`).default} />
        </div>
      </div>
      <div className="container landing-footer">
        <div className="sep"></div>
        <p className="responsive credit"></p>
        <div className="responsive contact" style={{textAlign: "right"}}>
          <Link to={`mailto:office@pixelworkssoftware.com?subject=${name}`}>Contact</Link>
          <Link to={`/${privacyPolicyId}`}>Privacy</Link> 
        </div>
      </div>
      </div>
    </React.Fragment>
  );
}

export default LandingTemplate;